import request from "@/common/utils/request";
import Vue from "vue";

export function edit(data) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/front-user/update",
    method: "post",
    data
  });
}
export function getList(params) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/front-user/list",
    method: "get",
    params
  });
}
export function getFrontUserList(params) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/front-user/list",
    method: "get",
    params
  });
}