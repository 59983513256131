<!-- xuwencheng  2022年6年25日11:00  这是我的活动添加列表 -->
<!-- 编辑 -->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="65%"
    @close="close"
  >
    <el-form ref="form" label-width="200px" :model="form" :rules="rules">
      <el-form-item label="用户姓名" prop="nickname">
        <el-input v-model="form.nickname" placeholder="请输入用户姓名" />
      </el-form-item>
      <el-form-item label="用户头像" prop="imgUrl">
        <el-upload
          v-model="form.imgUrl"
          action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
          :before-upload="beforeAvatarUpload"
          class="avatar-uploader"
          :headers="{
            'X-Token': token,
          }"
          :on-success="handleAvatarSuccess"
          :show-file-list="false"
        >
          <img v-if="imageUrl" class="avatar" :src="imageUrl" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>

    <el-button class="baocun" size="medium" type="primary" @click="save">
      保存
    </el-button>
  </el-dialog>

  <!-- 增加 -->
</template>
  
  <script>
/* eslint-disable*/
import { edit } from "@/api/cyonghu";
import { getToken } from "@/common/utils/auth";

//import { number } from 'echarts'
export default {
  name: "editcpeople",
  data() {
    const validatesurfacePlot = (rule, value, callback) => {
      console.log(this.form.imgUrl);
      if (this.form.imgUrl === "") {
        callback(new Error("请添加图片!!!!"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var contactPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      title: "",
      dialogFormVisible: false,
      options: [],
      value: [],
      list: [],
      loading: false,
      optionstwo: [],
      valuetwo: [],
      listtwo: [],
      loadingtwo: false,
      fileUrl: "", //视频url
      token: "",
      imageUrl: "",
      formData: {
        key: "",
        policy: "",
        OSSAccessKeyId: "",
        signature: "",
      },
      ruleForm: {
        checkPass: "",
      },
      videoForm: {
        Video: "",
      },
      options2: [],
      options3: [],
      aliyunform: {},
      fuwenben: "",
      form: {
        sort: 0,
        unionid: "",
        cuserId: "",
        imgUrl: "",
      },
      activated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.play();
      },
      deactivated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.pause();
      },

      rules: {
        nickname: [{ required: true, trigger: "blur", message: "请输入姓名" }],
        lecturerIntro: [
          { required: true, trigger: "blur", message: "请输入讲师介绍" },
        ],
        selfSignature: [
          { required: true, trigger: "blur", message: "请输入个性签名" },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: contactPhone,
          },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入密码" },
          { validator: validatePass, trigger: "blur" },
        ],
        sort: [
          { required: true, trigger: "blur", message: "请输入排序" },
          { pattern: /^(\d+)((?:\.\d+)?)$/, message: "请输入合法数字" },
        ],
        checkPass: [
          { required: true, trigger: "blur", message: "请确认密码" },
          { validator: validatePass2, trigger: "blur" },
        ],

        unionid: [
          { required: true, trigger: "change", message: "请选择unionid" },
        ],
        cuserId: [
          { required: true, trigger: "change", message: "请选择cuserId" },
        ],
        imgUrl: [
          { required: true, trigger: "change", message: "请添加图片" },
          { validator: validatesurfacePlot, trigger: "change" },
        ],
        vUrl: [{ required: true, trigger: "change", message: "请添加视频" }],
        classification: [
          { required: true, trigger: "change", message: "请选择分类" },
        ],
        userId: [
          { required: true, trigger: "blur", message: "请选择所属用户" },
        ],
        vLocked: [
          { required: true, trigger: "change", message: "状态不能为空" },
        ],
      },
    };
  },
  created() {
    let t = getToken();
    this.token = t;
    // this.getUrls();
  },
  // mounted() {
  //   this.list = this.states.map((item) => {
  //     return { value: `value:${item}`, label: `label:${item}` }
  //   })
  //   console.log(this.list)
  // },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const msg = getcurid({
          keyWord: query,
        });
        msg.then((res) => {
          this.list = res.data.map((item) => {
            return {
              value: `${item.unionid}`,
              label: `${item.unionid}`,
            };
          });
        });
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            console.log(item);
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    remoteMethodtwo(query) {
      if (query !== "") {
        this.loadingtwo = true;
        const msg = getcurid({
          keyWord: query,
        });
        msg.then((res) => {
          this.listtwo = res.data.map((item) => {
            return {
              value: `${item.cuserId}`,
              label: `${item.cuserId}`,
            };
          });
        });
        setTimeout(() => {
          this.loadingtwo = false;
          this.optionstwo = this.listtwo.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.optionstwo = [];
      }
    },
    handleChange(file, fileList) {
      this.formData.key = this.aliyunform.dir + "/" + file.name;
      this.formData.policy = this.aliyunform.policy;
      this.formData.OSSAccessKeyId = this.aliyunform.accessid;
      this.formData.signature = this.aliyunform.signature;
    },
    handlePreview(file) {
      console.log(file);
    },

    handleAvatarSuccessone(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.imgUrl = res.data;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return true;
    },
    showEdit(row) {
      if (!row) {
        this.title = "添加";
      } else {
        this.title = "编辑";
        this.form = Object.assign({}, row);
        console.log(row);
        this.imageUrl = row.imgUrl;
        this.dialogFormVisible = true;
      }
    },
    close() {
      let userId = this.form.userId;
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      if (!this.form.id) {
        this.form.userId = userId;
      }
      console.log(this.form);
      this.dialogFormVisible = false;
      this.imageUrl = "";
      // this.form = ''
    },
    save() {
      // this.$router.push('/other/workflow')

      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            console.log(this.form.id);
            const msg = edit(this.form);
            msg.then((res) => {
              console.log(res.msg);

              if (res.msg === "修改成功") {
                this.$alert(res.msg, "删除成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                  showClose: false,
                }).then((res) => {
                  this.$emit("fetch-data");
                  this.close();
                });
              } else {
                this.$alert(res.msg, "修改失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                  showClose: false,
                });
              }
            });
          } else {
            this.form.cuserId = this.form.cuserId + 0;
            const msg = doAdd(this.form);
            console.log(this.form);
            msg.then((res) => {
              console.log(res.msg);
              if (res.msg === "新增成功") {
                this.$baseMessage(
                  res.msg,
                  "success",
                  "vab-hey-message-success"
                );
                this.$emit("fetch-data");
                this.close();
                this.$router.push({
                  path: "/huodong/huodonglist",
                });
              }
            });
          }
        }
      });
    },
    async fetchDatathree() {
      let that = this;
      let queryForm = {};
      const res = await AssignEmployees(queryForm);
      console.log(res.data.list);
      this.options3 = res.data.list;
      if (!this.form.id) {
        this.form.userId = this.options3.filter(
          (item) => item.nickName === res.data.nickName
        )[0].id;
      }

      console.log(res.data.nickName);
    },
    async fetchData() {
      let queryForm = {};
      const res = await getListtwo(queryForm);

      this.options2 = res.articleCategoryList;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.imgUrl = res.data;
      console.log(this.form.imgUrl);
    },
    async getUrls() {
      let queryForm = {};
      const res = await getUrl(queryForm);
      this.fileUrl = "https://oss.laidedui.com";

      console.log(res);
      this.aliyunform = res;
      console.log(this.fileUrl);
    },
    nickNameChange() {
      this.$forceUpdate();
    },
  },
};
</script>
  <style>
/* .huakuai {
      position: relative;
      left: 96%;
    } */
.avatar-uploader .el-upload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  width: 178px;
  height: 178px;
  font-size: 28px;
  line-height: 178px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  display: block;
  width: 178px;
  height: 178px;
}
/* .videssad {
      display: block;
      width: 360px;
      height: 178px;
    } */
.el-upload-dragger {
  position: relative;
  box-sizing: border-box;
  width: 180px;
  height: 180px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.baocun {
  width: 100%;
  height: 100%;
}
</style>
  