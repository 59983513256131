<template>
  <div class="user-management-container">
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-input
                v-model="queryForm.nickname"
                size="mini"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="search"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <!-- <el-table-column align="center" show-overflow-tooltip type="selection" /> -->
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column
        align="center"
        label="openid"
        prop="openid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="昵称"
        prop="nickname"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="微信头像">
        <template slot-scope="scope">
          <el-image
            class="fengmiantu"
            v-if="scope.row.imgUrl"
            :src="scope.row.imgUrl"
            :preview-src-list="[scope.row.imgUrl]"
          />
          <span v-else>暂无图片</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        show-overflow-tooltip
        width="125"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <!-- <el-button type="text" @click="handleDelete(row)">删除</el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <!-- <lianxi /> -->
  </div>
</template>
  
  <script>
// import lianxi from '@/views/lianxi/lianxi'
import edit from "./model/edit.vue";
import { getList } from "@/api/cyonghu"; // doDelete

export default {
  name: "cduanyonghu",
  components: { edit }, // lianxi
  data() {
    return {
      list: [],
      value: "",
      searchForm: {},
      options1: [
        {
          id: "0",
          creClasName: "未审核",
        },
        {
          id: "1",
          creClasName: "审核通过",
        },
        {
          id: "2",
          creClasName: "审核被驳回",
        },
      ], //审核状态
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectRows: "",
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        className: "",
      },
    };
  },
  created() {
    this.fetchData();
  },

  activated() {
    this.fetchData();
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val;
    },
    search() {
      this.fetchData();
    },
    addfenlei() {
      this.$router.push({
        path: "/shangpin/fenleiAdd",
      });
    },
    optosall() {
      console.log(this.queryForm.status);
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs["edit"].showEdit(row);
      } else {
        this.$refs["edit"].showEdit();
      }
    },
    async changeSwitch(row) {
      console.log(row);
      if (row.id) {
        const { msg } = await updateGoodsClassLocked(row);
        console.log(msg);
        this.$baseMessage("修改成功", "success", "vab-hey-message-success");
        await this.fetchData();
      }
    },
    handleDelete(row) {
      if (row.id) {
        this.$baseConfirm("你确定要删除当前项吗", null, async () => {
          const { msg } = await deleteGoodsType({ id: row.id });
          this.$baseMessage(msg, "success", "vab-hey-message-success");
          await this.fetchData();
        });
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join();
          this.$baseConfirm("你确定要删除选中项吗", null, async () => {
            const { msg } = await deleteGoodsType({ id });
            this.$baseMessage(msg, "success", "vab-hey-message-success");
            await this.fetchData();
          });
        } else {
          this.$baseMessage("未选中任何行", "error", "vab-hey-message-error");
        }
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    handleEditfour(row) {
      if (row.id) {
        this.$refs["Editxiugai"].showEdit(row);
      } else {
        this.$refs["Editxiugai"].showEdit();
      }
    },
    queryData() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      const res = await getList(this.queryForm);
      console.log(res);
      this.list = res.data.dataList;

      this.total = res.data.total;
      this.listLoading = false;
    },
  },
};
</script>
  <style>
.fengmiantu {
  width: 50px;
  height: 50px;
}
</style>
  